import { createSlice } from '@reduxjs/toolkit';
import {
  getCurrentProject,
  getCurrentSector,
  mapUserToNewFormat
} from '../../utils/userUtils';

/**
 * Note: The projectReducer.js and projectActions.js files were migrated to RTK and combined into this slice.
 * For this reason, some naming conventions in use in other slices are not followed here,
 * as several files would need to be updated if we renamed some of these identifiers.
 */

export const projectSliceKey = 'projectState';

const initialState = {
  allUsers: [],
  allAssignableUsers: [],
  allProjects: [],
  allSectors: [],
  projectSelected: getCurrentProject()?.id ?? null,
  sectorSelected: getCurrentSector()?.id ?? null,
  sectorSelectedRename: { id: null, name: null, visible: null },
  sectorViewNotification: false,
  props: {}
};

const { actions, reducer } = createSlice({
  name: projectSliceKey,
  initialState,
  reducers: {
    setProject(state, { payload }) {
      // Currently selected project's ID
      state.projectSelected = payload;

      // All users in the current project
      state.allUsers =
        state.allProjects
          ?.find((proj) => proj.id === payload)
          ?.users?.map((user) => mapUserToNewFormat(user)) ?? [];

      // All active users in the current project
      state.allAssignableUsers = state.allUsers.filter((user) => user.isActive);
    },
    setSector(state, { payload }) {
      state.sectorSelected = payload;
    },
    setAllProjects(state, { payload }) {
      state.allProjects = payload;
    },
    setAllSectors(state, { payload }) {
      state.allSectors = payload;
    },
    setSectorSelectedRename(state, { payload }) {
      state.sectorSelectedRename = payload;
    },
    setSectorViewNotification(state, { payload }) {
      state.sectorViewNotification = payload;
    },
    setProps(state, { payload }) {
      state.props = payload;
    },
    setCleanSelectedProject(state) {
      state.projectSelected = null;
      state.sectorSelected = null;
    }
  }
});

const baseSelector = (state) => state[projectSliceKey];

export function getAllAssignableUsersSelector(state) {
  return baseSelector(state).allAssignableUsers;
}

export function getSelectedSectorSelector(state) {
  const projectState = baseSelector(state);
  return projectState.allSectors.find(
    (sector) => sector.id === projectState.sectorSelected
  );
}

export const projectReducer = reducer;
export const {
  setProject,
  setSector,
  setAllProjects,
  setAllSectors,
  setSectorSelectedRename,
  setSectorViewNotification,
  setProps,
  setCleanSelectedProject
} = actions;
