import React from 'react';
import IconComponent from './icon';
import { getSessionTokenData } from '../utils/userUtils';
import { roleTypes } from '../constants/role.constants';

export const menu = (t, companyState, userLang) => {
  const userSessionStorage = getSessionTokenData();

  const allowedCompanies = [721, 306, 272, 92, 572, 204];
  const allowedRoles = [
    roleTypes.SUPERADMIN,
    roleTypes.ADMIN,
    roleTypes.PROJECTLEADER,
    roleTypes.PLANNER
  ];

  let newMenu =
    companyState?.id === 29 || companyState?.id === 31
      ? [
          {
            available: true,
            id: '0',
            title: t('modules.masterplan'),
            icon: 'pic-right',
            routeName: 'masterplan',
            go: (history) => {
              history.push('/masterplan');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '1',
            title: t('modules.lookahead'),
            icon: 'pic-right',
            routeName: 'lookahead',
            go: (history) => {
              history.push('/lookahead/planification');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '2',
            title: t('modules.weeklyplan'),
            icon: 'pic-right',
            routeName: 'weeklyplan',
            go: (history) => {
              history.push('/weeklyplan/commintments');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '3',
            title: t('modules.taktplanning'),
            icon: 'pic-right',
            routeName: 'taktplanning',
            go: (history) => {
              history.push('/taktplanning/units');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '4',
            title: t('modules.productivity'),
            icon: 'pic-right',
            routeName: 'productivity',
            go: (history) => {
              history.push('/productivity/assign');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '8',
            title: t('modules.analytics'),
            icon: 'bar-chart',
            routeName: 'analytics',
            go: (history) => {
              history.push('/analytics-v2');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          }
        ]
      : userLang.includes('es')
        ? [
            {
              available: true,
              id: '0',
              title: t('modules.masterplan'),
              icon: 'pic-right',
              routeName: 'masterplan',
              go: (history) => {
                history.push('/masterplan');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '1',
              title: t('modules.lookahead'),
              icon: 'pic-right',
              routeName: 'lookahead',
              go: (history) => {
                history.push('/lookahead/planification');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '2',
              title: t('modules.weeklyplan'),
              icon: 'pic-right',
              routeName: 'weeklyplan',
              go: (history) => {
                history.push('/weeklyplan/commintments');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '8',
              title: t('modules.analytics'),
              icon: 'bar-chart',
              routeName: 'analytics',
              go: (history) => {
                history.push('/analytics-v2');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            }
          ]
        : [
            {
              available: true,
              id: '0',
              title: t('modules.masterplan'),
              icon: 'pic-right',
              routeName: 'masterplan',
              go: (history) => {
                history.push('/masterplan');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '1',
              title: t('modules.lookahead'),
              icon: 'pic-right',
              routeName: 'lookahead',
              go: (history) => {
                history.push('/lookahead/planification');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '2',
              title: t('modules.weeklyplan'),
              icon: 'pic-right',
              routeName: 'weeklyplan',
              go: (history) => {
                history.push('/weeklyplan/commintments');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '8',
              title: t('modules.analytics'),
              icon: 'bar-chart',
              routeName: 'analytics',
              go: (history) => {
                history.push('/analytics-v2');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            }
          ];

  const taktPlanningMenu = [
    {
      available: true,
      id: '9',
      title: t('modules.taktplanning'),
      icon: 'pic-right',
      routeName: 'taktplanning',
      go: (history) => {
        history.push('/taktplanning/units');
      },
      svgIcon: (routeName) => <IconComponent name={routeName} />
    },
    {
      available: true,
      id: '10',
      title: t('modules.takt_analytics'),
      icon: 'bar-chart',
      routeName: 'extraDashboard',
      go: (history) => {
        history.push('/extraDashboard');
      },
      svgIcon: (routeName) => <IconComponent name={routeName} />
    }
  ];

  if (
    userSessionStorage &&
    allowedRoles.includes(userSessionStorage.role) &&
    allowedCompanies.includes(parseInt(userSessionStorage.companyId))
  ) {
    newMenu = [...newMenu, ...taktPlanningMenu];
  }

  return newMenu;
};
