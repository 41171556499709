export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE = 'UPDATE';
export const UPDATE2 = 'UPDATE2';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const SET_USER = 'SET_USER';

export const userActions = {
  setUserLogged: (loggedUser) => ({
    type: USER_LOGIN,
    payload: loggedUser
  }),
  setUserLoggedout: () => ({
    type: USER_LOGOUT,
    payload: null
  }),
  setUserUpdate: () => ({
    type: UPDATE,
    payload: null
  }),
  setUserUpdate2: () => ({
    type: UPDATE2,
    payload: null
  }),
  setUserUpdateImage: (image) => ({
    type: UPDATE_IMAGE,
    payload: image
  }),
  setUser: (user) => ({
    type: SET_USER,
    payload: user
  })
};
