import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import i18n from '../../i18n';
import {
  modificationRequestsFetchRequested,
  modificationRequestsFetchFailed,
  modificationRequestsFetchSucceeded,
  modificationRequestsApproveRequested,
  modificationRequestsApproveSucceeded,
  modificationRequestsApproveFailed,
  modificationRequestsRejectRequested,
  modificationRequestsRejectSucceeded,
  modificationRequestsRejectFailed,
  modificationRequestsEnablePreviewRequested,
  modificationRequestsDisablePreviewRequested
} from '../slices/ganttSlice';

import { getSaveMasterplanSelector } from '../slices/nonSerializableSlice';
import { getPermission } from '../../utils/authUtils';
import { activityModificationService as modificationRequestsService } from '../../services/activitymodification.service';

export function* modificationRequestsWatcherSaga() {
  // Request sagas
  yield takeLatest(
    modificationRequestsFetchRequested.type,
    modificationRequestsFetchRequestedWorkerSaga
  );
  yield takeLatest(
    modificationRequestsApproveRequested.type,
    modificationRequestsApproveRequestedWorkerSaga
  );
  yield takeLatest(
    modificationRequestsRejectRequested.type,
    modificationRequestsRejectRequestedWorkerSaga
  );
  yield takeLatest(
    modificationRequestsEnablePreviewRequested.type,
    modificationRequestsEnablePreviewRequestedWorkerSaga
  );
  yield takeLatest(
    modificationRequestsDisablePreviewRequested.type,
    modificationRequestsDisablePreviewRequestedWorkerSaga
  );

  // Success sagas
  yield takeLatest(
    modificationRequestsApproveSucceeded.type,
    modificationRequestsApproveSucceededWorkerSaga
  );
  yield takeLatest(
    modificationRequestsRejectSucceeded.type,
    modificationRequestsRejectSucceededWorkerSaga
  );
}

export function* modificationRequestsFetchRequestedWorkerSaga({
  payload
} = {}) {
  let selectedActivitiesIds;
  const checkedActivities = [
    ...document.querySelectorAll('.gantt-checkbox-column')
  ]
    .filter((checkbox) => checkbox.checked)
    .map((checkbox) => checkbox.closest('.gantt_row').getAttribute('task_id'));
  const activityId = payload?.activityId;
  const ganttInstance = getGanttInstance();

  if (activityId) {
    selectedActivitiesIds = [activityId];
  } else if (checkedActivities.length) {
    selectedActivitiesIds = checkedActivities;
  } else {
    selectedActivitiesIds = [];
    ganttInstance.eachTask((task) => selectedActivitiesIds.push(task.id));
  }

  try {
    const activities = yield all(
      selectedActivitiesIds.map((activityId) =>
        call([ganttInstance, ganttInstance.getTask], activityId)
      )
    );

    const modificationRequests = []
      .concat(
        ...activities
          .filter((activity) => activity.activityModifications?.length)
          .map(({ activityModifications: requests }) => requests)
      )
      .sort((mrA, mrB) => new Date(mrB.createdAt) - new Date(mrA.createdAt));

    yield put({
      type: modificationRequestsFetchSucceeded.type,
      payload: modificationRequests
    });
  } catch (e) {
    yield put({ type: modificationRequestsFetchFailed.type });
  }
}

export function* modificationRequestsApproveRequestedWorkerSaga({
  payload: { activityId, modificationRequestId, newDates, activityIdGet }
}) {
  const response = yield call(
    updateModificationRequest,
    activityId,
    modificationRequestId,
    'approved',
    activityIdGet
  );

  const actionConfig = response
    ? {
        type: modificationRequestsApproveSucceeded.type,
        payload: { activityId, newDates }
      }
    : { type: modificationRequestsApproveFailed.type };

  yield put(actionConfig);
}

export function* modificationRequestsRejectRequestedWorkerSaga({
  payload: { activityId, modificationRequestId, activityIdGet }
}) {
  const response = yield call(
    updateModificationRequest,
    activityId,
    modificationRequestId,
    'rejected',
    activityIdGet
  );

  const actionConfig = response
    ? { type: modificationRequestsRejectSucceeded.type }
    : { type: modificationRequestsRejectFailed.type };

  yield put(actionConfig);
}

export function* updateModificationRequest(
  activityId,
  modificationRequestId,
  state,
  activityIdGet
) {
  if (getPermission('masterplan.gantt') === 'V') {
    displayNoPermissionErrorMessage();

    return;
  }
  let selectedActivitiesIds;
  const ganttInstance = getGanttInstance();

  const modificationRequest = updateModificationRequestObject(
    activityId,
    modificationRequestId,
    state
  );
  const response = yield call(
    [modificationRequestsService, modificationRequestsService.update],
    modificationRequest
  );

  if (activityIdGet) {
    selectedActivitiesIds = [activityId];
    const activities = yield all(
      selectedActivitiesIds.map((activityId) =>
        call([ganttInstance, ganttInstance.getTask], activityId)
      )
    );

    const modificationRequests = []
      .concat(
        ...activities
          .filter((activity) => activity.activityModifications?.length)
          .map(({ activityModifications: requests }) => requests)
      )
      .sort((mrA, mrB) => new Date(mrB.createdAt) - new Date(mrA.createdAt));

    yield put({
      type: modificationRequestsFetchSucceeded.type,
      payload: modificationRequests
    });
  } else {
    yield modificationRequestsFetchRequestedWorkerSaga();
  }

  return response;
}

/**
 * The logic in this saga was ported from the old request approval implementation.
 * It probably can be simplified, but I still couldn't take the time to do it.
 */
export function* modificationRequestsApproveSucceededWorkerSaga({
  payload: { activityId, newDates }
}) {
  const saveMasterplan = yield select(getSaveMasterplanSelector);
  yield call(applyChangesToGantt, activityId, newDates);
  yield call(saveMasterplan);
  message.success(i18n.t('success_modification_activity'), 5);
}

export function* modificationRequestsRejectSucceededWorkerSaga() {
  message.success(i18n.t('rejected_modification_activity'), 5);
}

export function* modificationRequestsEnablePreviewRequestedWorkerSaga({
  payload: { activityId, newDates }
}) {
  yield call(applyChangesToGantt, activityId, newDates);
}

export function* modificationRequestsDisablePreviewRequestedWorkerSaga() {
  // Dont work
}

export function* applyChangesToGantt(activityId, newDates) {
  const parseDate = (date) => ganttInstance.date.parseDate(date, 'xml_date');
  const ganttInstance = getGanttInstance();
  ganttInstance.activityPreview = activityId;
  const activity = yield call(
    [ganttInstance, ganttInstance.getTask],
    activityId
  );
  const calendar = yield call(
    [ganttInstance, ganttInstance.getCalendar],
    activity.calendar_id || ganttInstance.defaultCalendar
  );

  const startNew = parseDate(newDates.start);
  const endNew = parseDate(newDates.end);
  const durationNew = yield call(
    [calendar, calendar.calculateDuration],
    startNew,
    endNew
  );
  activity.previewObject = {
    oldDates: {
      start: activity.start_date,
      end: activity.end_date,
      duration: activity.duration
    },
    previewDates: {
      start: startNew,
      end: endNew,
      duration: durationNew
    }
  };
  activity.start_date = startNew;
  activity.end_date = endNew;
  activity.duration = durationNew;
  activity.newDuration = activity.duration;
  activity.for_disable_milestone_duration = activity.duration;
  yield call([ganttInstance, ganttInstance.updateTask], activity.id);
  yield call([ganttInstance, ganttInstance.modifyLagCustom], activity);
  yield call([ganttInstance, ganttInstance.autoSchedule]);

  return {
    startDate: activity.start_date,
    endDate: activity.end_date,
    duration: activity.duration
  };
}

const updateModificationRequestObject = (
  activityId,
  modificationRequestId,
  state
) => {
  const { id: userApproved } = getSignedInUser();
  const modificationRequest = getModificationRequestObject(
    activityId,
    modificationRequestId
  );

  Object.assign(modificationRequest, { userApproved, state });

  return modificationRequest;
};

const getModificationRequestObject = (activityId, modificationRequestId) => {
  const activity = getGanttInstance().getTask(activityId);

  return activity?.activityModifications?.find(
    (req) => req.id === modificationRequestId
  );
};

const displayNoPermissionErrorMessage = () =>
  message.error(i18n.t('without_permission_to_save'), 5);

const getGanttInstance = () => window.to_use_react_gantt;

const getSignedInUser = () => JSON.parse(localStorage.getItem('user'));
