/* eslint-disable no-case-declarations */
import {
  USER_LOGIN,
  USER_LOGOUT,
  UPDATE,
  UPDATE2,
  UPDATE_IMAGE,
  SET_USER
} from '../actions/userActions';
/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function userReducer(
  state = {
    isUserLogged: false,
    user: null,
    update: false,
    update2: false
  },
  action
) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        isUserLogged: true,
        user: action.payload
      };
    case USER_LOGOUT:
      return {
        ...state,
        isUserLogged: false,
        user: null
      };
    case UPDATE:
      return {
        ...state,
        update: !state.update
      };
    case UPDATE2:
      return {
        ...state,
        update2: !state.update2
      };
    case UPDATE_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          image: action.payload
        }
      };
    case SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          lastname: action.payload.lastname
        }
      };
    default:
      return state;
  }
}

export default userReducer;
