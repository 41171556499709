const dummy = {
  order: [
    {
      name: 'start_date',
      label: 'Start',
      align: 'center',
      resize: true,
      width: '75',
      editor: {
        type: 'date',
        map_to: 'start_date'
      },
      hide: false,
      data_type: 'date',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true,
      order_by: 'asc'
    }
  ],
  filters: [
    {
      name: 'text',
      label: 'Activity',
      width: '270',
      resize: true,
      tree: true,
      editor: {
        type: 'text',
        map_to: 'text'
      },
      hide: false,
      data_type: 'string',
      groupable: false,
      orderable: false,
      orderable_switch: ['A → Z', 'Z → A'],
      filterable: true,
      equals: 'includes',
      filter_by: 'Submittal'
    }
  ],
  range: ['2021-01-03', '2024-01-03'],
  columns: [
    {
      label: '',
      value: 'checked'
    },
    {
      label:
        '<div style="font-size: 26px; color: #333333;font-weight: 400; cursor: pointer" onclick="window.to_use_react_gantt.createTask({ duration: 9 })"> + </div>',
      value: 'buttons'
    },
    {
      label: 'ID',
      value: 'correlative_id'
    },
    {
      label: 'Activity',
      value: 'text'
    }
  ],
  zoom: 'DAYS',
  visualization: {
    areLinksVisible: false,
    areSlackVisible: false,
    areNumtasksVisible: false,
    areBaselineVisible: true,
    isTodaylineVisible: false,
    areSubmittalsVisible: false
  },
  colors: {
    bar: 'tags',
    wbs: true
  },
  collapsed: [1659635512325]
};

export default dummy;
